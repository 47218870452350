import { CompleteLayout } from '../../components/forms/CompleteLayout';
import { HeadInner } from '../../components/Head';
import { Layout } from '../../components/layout';

export const Head = () => (
  <HeadInner
    path='/contact/complete/'
    title='お問い合わせありがとうございます'
    noindex
  />
);

const CompletePage = () => {
  const links = [
    { name: 'お問い合わせ', path: '/contact/' },
    { name: 'お問い合わせを受け付けました', path: '/contact/complete/' },
  ];

  return (
    <Layout
      header='simple'
      footer='simple'
      background='simple'
      isBottomMenu={false}
      links={links}
    >
      <CompleteLayout>
        <h2>お問い合わせありがとうございます</h2>
        <p>ご入力いただいた内容を確認した後、担当者よりご連絡いたします。</p>
      </CompleteLayout>
    </Layout>
  );
};

export default CompletePage;
